import React,{ useState,useEffect} from "react";
import { AgGridReact } from 'ag-grid-react';
import {useDispatch,useSelector} from 'react-redux'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { apiPost } from "../../../api/apiCommon";

const Preference =()=>
{
        const reduxState=useSelector(state=>state);
        const [columnDef, setcolumnDef] = useState([
                {headerName:'Select',field:'select',AgCheckbox:true},
                {headerName:'Emp ID',field:'empID'},
                {headerName:'Emp Name',field:'name'},
                {headerName:'Emp Mail',field:'empEmail'},
                {headerName:'Log In',field:'DOB'},
                {headerName:'Log Off',width:'210%',field:'address'},
            
               
                ]);
                const [gridApi, setgridApi] = useState(null);
                const onGridReady = (params) => {
                        setgridApi(params.api);
                        params.api.sizeColumnsToFit();
                    }
                    useEffect(() => {
                        getEmpList();
                     }, []);
const getEmpList=async ()=>
{
 
      const response= await   apiPost('admin/getallemployeeloginactivity', {userUID:reduxState.userReducer.userUID,
              roleUID:reduxState.userReducer.roleUID});
              if(!response.data.error)
              {
                setcolumnDef(response.data.response);
                
                  /* console.log('details',response.data.empDetails) */
                  
              }
      }
   return(
    <div className="wrapper">
            <div className="container-fluid">
            <div className="row">
            <div className="col-sm-12">
                <div className="page-title-box">
                    <div className="btn-group pull-right">

                    </div>
                    <h4 className="page-title">Employee Reports</h4>
                </div>
            </div>
        </div>
            
            <div className="row">
            <div className="col-sm-12">
            <div className="card">
             <div className="card-body">
          
            <h4 className="mt-0 header-title">Employment Reference</h4>
         
            <div className="row" style={{paddingBottom: '4px'}}>
 <div className="col-sm-5">
 </div>
 <div className="col-sm-4">
   </div>
 <div className="col-sm-3">
 <input class="btn btn-primary " type="date" style={{float:'top'}}   placeholder="Search"/>
 <button  type="button" class="btn btn-success btnz" style={{float:'right'}}>Export all</button>
 
 </div>
</div>
            <div className="ag-theme-alpine" style={{height:450, width: 'auto'}}>
                        
                        <AgGridReact
       defaultColDef={{
         editable: false,
         enableRowGroup: true,
         enablePivot: true,
         enableValue: true,
         sortable: true,
         resizable: true,
         filter: true,
         floatingFilter: true,
         filter: 'agTextColumnFilter',
         minWidth: 100,
         cellStyle:{textAlign:'left'}
       }}
       suppressRowClickSelection={true}
       groupSelectsChildren={true}
       debug={true}
       rowSelection={'multiple'}
      /*  rowGroupPanelShow={'always'} */
       pivotPanelShow={'always'}
       enableRangeSelection={true}
       pagination={true}
       //onGridReady={onGridReady}
       columnDefs={columnDef}
      
   />
  </div>
  </div>
  </div>
            </div>
            </div>
           

            </div>
            </div>);
}
export default Preference;