import { useState,useEffect} from "react";
import {useDispatch,useSelector} from 'react-redux'
import {userAction} from '../customRedux/actions/Actions'
import {userConstant} from '../customRedux/constants/actionConstant'
import {setLogoutUser} from '../api/apiCommon'
import { useHistory } from "react-router-dom";
import { Link} from 'react-router-dom';
import image from '../assets/img/logo.png'
import profileImg from '../assets/img/man-icon.png'
import 'react-alert-confirm/lib/style.css';
import AlertConfirm from 'react-alert-confirm';
import { apiPost } from "../api/apiCommon";
const Header=()=>
{
    const reduxState=useSelector(state=>state);
    const dispatch=useDispatch();
    const history = useHistory();
  
    const [defaulProfileIMG, setDefaulProfileIMG] = useState((parseInt(reduxState.userReducer.roleUID)==3)?'':profileImg);
    useEffect(() => {
        if(window.menuFunctionVariableCheck==0)
        {
            window.menuFunctionVariable.init();
        }
      
        
        window.addEventListener('resize', async function() {
           
           var innerWidth=window.innerWidth?window.innerWidth:null;
           if(innerWidth!=null)
           {
               
                if(innerWidth>=992)
                {
                    document.getElementById("navigation").style.display = "block";
                }
                else if(innerWidth<=990)
                {
                    document.getElementById("navigation").style.display = "none";
                }
           }
         });
      }, []);

      const confirmLogout=(event)=>
      {
         event.preventDefault();
        
          AlertConfirm({
            title: 'Confirm to Logout?',
            desc: 'Are you sure to do this...!',
            onOk: () => {
               Logout()
            },
            onCancel: () => {
              console.log('cancel');
            }
          });
      }

      const Logout=()=>
      {
        setLogoutUser();
        dispatch(userAction(userConstant.logoutAdminUser,{}));
        history.push("/");
      }
      useEffect(() => {
        getEmpList();
      
      }, []);
      const [rowData, setrowData] = useState({});
      const [imageDataURL, setimageDataURL] = useState('');
     
      const getEmpList=async ()=>
      {
       
        
        
          const response= await  apiPost('admin/getaddphoto',{userUID:reduxState.userReducer.userUID,
            roleUID:reduxState.userReducer.roleUID
                   });
        
            if(!response.data.error)
            {
              
                setrowData(response.data.empDetails[0]);
                setimageDataURL(response.data.ImageURL);
                setDefaulProfileIMG(response.data.ImageURL+'photo/'+response.data.empDetails[0].photoFileName);
               
                
            }
        
      }
   return (
    <header id="topnav">
        <div className="topbar-main">
            <div className="container-fluid">

                
                <div className="logo">
                    <a href="#" onClick={e=>e.preventDefault()} className="logo">
                        <img  src={image} alt="" className="logo-large" style={{height: '30px'}} />
                        
                    </a>

                </div>
          
               

                 

                <div className="menu-extras topbar-custom">

                <li class="list-inline-item dropdown notification-list hide-phone"  >
                    <div style={{position:"relative",left:'420px'}}className="dropdown-item noti-title1" >
                    <h6>Welcome {reduxState.userReducer.roleName} : {reduxState.userReducer.name}</h6>
                                </div>

                            </li>

                    <ul className="list-inline float-right mb-0">

                         
                    
                    <li class="list-inline-item dropdown notification-list hide-phone">
                    
                 {/*  <div className="dropdown-item noti-title1" style={{backgroundClip:''}}>
                                   <h6>{reduxState.userReducer.name}</h6>
                                </div>  */}
                               
                            </li>
                        <li className="list-inline-item dropdown notification-list"> 
                        <a className="nav-link dropdown-toggle arrow-none waves-effect nav-user" data-toggle="dropdown" href="#" onClick={e=>e.preventDefault()} role="button" aria-haspopup="false" aria-expanded="false">
                        <img src={defaulProfileIMG} /* alt="user"  */className="rounded-circle" />
     
           </a>

           
                       
                            <div className="dropdown-menu dropdown-menu-right profile-dropdown ">
                              
                            {/*     <div className="dropdown-item noti-title">
                                    <h5>Welcome {reduxState.userReducer.name}</h5>
                                </div> */}
                                <a className="dropdown-item" href="#" onClick={e=>e.preventDefault()}><i className="mdi mdi-account-circle m-r-5 text-muted"></i> Profile</a>
                                <div className="dropdown-divider"></div>
                                <a className="dropdown-item" href="#" onClick={e=>confirmLogout(e)}>
                                    <i className="mdi mdi-logout m-r-5 text-muted"></i> Logout</a>

                            </div>
                        </li>
                        <li className="menu-item list-inline-item">
                          
                            <a className="navbar-toggle nav-link">
                                <div className="lines">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </a>
                            
                        </li>

                    </ul>

                </div>

             

                <div className="clearfix"></div>

            </div> 
        </div>
        

       
        <div className="navbar-custom" style={{width:'105%'}}>
            <div className="container-fluid">
                <div id="navigation">
                    
                    
                    {reduxState.userReducer.roleUID==1 ? (
                        <ul className="navigation-menu text-center">
                        <li className="has-submenu ">
                          <Link to="/corporate/admin/dashboard"><i className="mdi mdi-airplay"></i>Dashboard</Link>
                        </li>

                        <li className="has-submenu">
                          <Link to="/corporate/admin/newlyoffered"><i className="mdi mdi-airplay"></i>Newly Offered</Link>
                        </li>

                        <li className="has-submenu ">
                          <Link to="/corporate/admin/internship"><i className="mdi mdi-layers"></i>Internship Team</Link>
                        </li>

                        <li className="has-submenu">
                         <Link to="/corporate/admin/onroll"><i className="mdi mdi-bullseye"></i>On roll team</Link>
                        </li>
                        <li className="has-submenu">
                         <Link to="/corporate/admin/exit"><i className="fa fa-close"></i>Exit Employee List</Link>
                        </li>
                       {/*  <li className="has-submenu">
                            <Link to="/corporate/admin/empreference"><i className="mdi mdi-gauge"></i>Employment Reference</Link>
                        </li> */}
                        </ul>
                    ) : ('')}

{reduxState.userReducer.roleUID==5 ? (
                        <ul className="navigation-menu text-center">
                        <li className="has-submenu ">
                          <Link to="/corporate/hr/dashboard"><i className="mdi mdi-airplay"></i>Dashboard</Link>
                        </li>
                        <li className="has-submenu">
                          <Link to="/corporate/hr/newlyoffered"><i className="mdi mdi-airplay"></i>Newly Offered</Link>
                        </li>
                        <li className="has-submenu ">
                          <Link to="/corporate/hr/internship"><i className="mdi mdi-layers"></i>Internship Team</Link>
                        </li>
                        <li className="has-submenu">
                         <Link to="/corporate/hr/onroll"><i className="mdi mdi-bullseye"></i>On roll team</Link>
                        </li>
                        <li className="has-submenu">
                         <Link to="/corporate/hr/exit"><i className="fa fa-close"></i>Exit Employee List</Link>
                        </li>
                        <li className="has-submenu ">
                          <Link to="/corporate/hr/timesheet"><i class="fa fa-clock-o"></i>Time Sheet</Link>
                        </li> 
                        </ul>
                        
                    ) : ('')}


{reduxState.userReducer.roleUID==2 ? (
                        <ul className="navigation-menu text-center">
                        <li className="has-submenu ">
                          <Link to="/corporate/taxinfo/dashboard"><i className="mdi mdi-airplay"></i>Dashboard</Link>
                        </li>
                    {/*     <li className="has-submenu ">
                          <Link to="/corporate/taxinfo/internship"><i className="mdi mdi-layers"></i>Internship Team</Link>
                        </li> */}
                       
                        <li className="has-submenu">
                         <Link to="/corporate/taxinfo/onroll"><i className="mdi mdi-bullseye"></i>On roll team</Link>
                        </li>
                        <li className="has-submenu">
                         <Link to="/corporate/taxinfo/exit"><i className="fa fa-close"></i>Exit Employee List</Link>
                        </li>
                        </ul>
                    ) : ('')}
                        

                        {reduxState.userReducer.roleUID==3 ? (
                        <ul className="navigation-menu text-center">
                        <li className="has-submenu ">
                          <Link to="/corporate/employee/dashboard"><i className="mdi mdi-airplay"></i>Dashboard</Link>
                        </li>
                        <li className="has-submenu ">
                          <Link to="/corporate/employee/profile"><i className="mdi mdi-airplay"></i>Profile</Link>
                        </li>
                      <li className="has-submenu ">
                          <Link to="/corporate/employee/timesheet"><i class="fa fa-clock-o"></i>Time Sheet</Link>
                        </li> 
                    {/*     <li className="has-submenu ">
                          <Link to="/corporate/hr/internship"><i className="mdi mdi-layers"></i>Internship Team</Link>
                        </li>
                        <li className="has-submenu">
                         <Link to="/corporate/hr/onroll"><i className="mdi mdi-bullseye"></i>On roll team</Link>
                        </li> */}
                        </ul>
                    ) : ('')}

{reduxState.userReducer.roleUID==6 ? (
                        <ul className="navigation-menu text-center">
                        <li className="has-submenu ">
                          <Link to="/corporate/server/dashboard"><i className="mdi mdi-airplay"></i>Dashboard</Link>
                        </li>
                        <li className="has-submenu">
                          <Link to="/corporate/server/newlyoffered"><i className="mdi mdi-airplay"></i>Newly Offered</Link>
                        </li>
                     
                        </ul>
                    ) : ('')}
                   
                </div> 
            </div>
        </div> 
    </header>
   );
}

export default Header;