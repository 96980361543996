import React from "react";
const Footer=()=>
{
    const d = new Date();
    let year = d.getFullYear(); 
   return (
    <footer className="footer">
    <div className="container-fluid">
        <div className="row">
            <div className="col-12">
                Bixware Internaluse by <a href="https://bixware.com" target="_blank" style={{fontWeight: 'bold'}}>Bixware Technologies</a> © {year}. All Rights Reserved
            </div>
        </div>
    </div>
</footer>
   );
}

export default Footer;