import { Link } from "react-router-dom";

const  Login =()=>
{
    return (
        <div id="app" style={{backgroundColor: '#3852A4',minHeight:'100vh',position:'fixed',overflowY:'scroll',width:'100%'}}>
        <main className="py-4">
           <div className="container">
              <div className="wrapper-page">
                 <div className="card">
                    <div className="card-body">
                       <div className="text-center mt-2 mb-4">
                          <a href="#" className="logo logo-admin">
                          <img
                             src="https://www.bixware.com/internaluse/assets/images/logo.png" height="40"
                             alt="logo" /></a>
                       </div>
                       <div className="alert alert-success" id="successmsg" style={{display:'none'}}> </div>
                       <div className="px-3 pb-3">
                          <form className="form-horizontal m-t-20" action="index.html">
                             <div className="form-group row">
                                <div className="col-12">
                                   <input id="email" type="email" className="form-control " name="email"
                                      placeholder="Enter Your Email" />
                                   <input id="randomstring" name="randomstring" type="text"
                                      className="form-control"  style={{display:'none'}} />
                                </div>
                             </div>
                             <div className="form-group row">
                                <div className="col-12">
                                   <input id="otpvalue" type="text" className="form-control otpvalue "
                                      name="otpvalue"    
                                      style={{display:'none'}} placeholder="Please Enter OTP" />
                                </div>
                             </div>
                             <div className="form-group row d-none">
                                <div className="col-12">
                                   <div className="custom-control custom-checkbox">
                                      <input type="checkbox" className="custom-control-input"
                                         id="customCheck1" />
                                      <label className="custom-control-label" htmlFor="customCheck1">Remember
                                      me</label>
                                   </div>
                                </div>
                             </div>
                             <div className="form-group text-center row m-t-20">
                                <div className="col-12">
                                      <Link to="/new-employment"
                                      className="btn btn-danger btn-block waves-effect waves-light otpbutton"
                                      type="button">Send OTP</Link>
                                </div>
                             </div>
                             <div className="form-group text-center row m-t-20">
                                <div className="col-12">
                                   <button
                                      className="btn btn-danger btn-block waves-effect waves-light loginbutton"
                                      type="submit" style={{display:'none'}}>Login </button>
                                </div>
                             </div>
                             <div className="form-group m-t-10 mb-0 row d-none">
                                <div className="col-sm-7 m-t-20">
                                   <a href="pages-recoverpw.html" className="text-muted"><i
                                      className="mdi mdi-lock"></i> <small>Forgot your password
                                   ?</small></a>
                                </div>
                                <div className="col-sm-5 m-t-20">
                                   <a href="pages-register.html" className="text-muted"><i
                                      className="mdi mdi-account-circle"></i> <small>Create an account
                                   ?</small></a>
                                </div>
                             </div>
                          </form>
                       </div>
                    </div>
                 </div>
              </div>
           </div>
        </main>
        </div>
    );
};

export default Login;