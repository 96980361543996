import { useState,useEffect} from "react";
import { useParams } from "react-router-dom";
import React from "react";
import {useDispatch,useSelector} from 'react-redux'
import { apiAction,toastAction } from "../../../customRedux/actions/Actions";
import { toastConstant,apiConstant } from "../../../customRedux/constants/actionConstant";
import {apiPost,apiFormDatePost} from '../../../api/apiCommon'
import image from '../../../assets/img/logo.png'
import { FileUploader } from "react-drag-drop-files";
import PhoneInput from 'react-phone-input-2'
//import '../phoneInput.css'
import { useHistory } from "react-router-dom";
//import 'bootstrap/dist/css/bootstrap.min.css';
import Modal from 'react-bootstrap/Modal';
import { pdfjs } from "react-pdf";
import { Document, Page } from "react-pdf";
import { a } from "react-spring";
import Button from "react-bootstrap-button-loader";
import 'react-alert-confirm/lib/style.css';
import AlertConfirm from 'react-alert-confirm';
//api
//import { employeeData } from "../../../api/api";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${
  pdfjs.version
}/pdf.worker.js`;

//redux action  and constants



//file types
const imgFileTypes = ["JPG","JPEG", "PNG"];
const docFileTypes = ["PDF", "DOCX","DOC"];

const EditEmployee =()=>
{
  const reduxState=useSelector(state=>state);
    const { id } = useParams();
    React.useEffect(() => { 
       getEmployee();getEmpTypeList(); }, []);
    const [, set] = useState([]);
    const  formatDate=(date)=> {
       var d = new Date(date),
           month = '' + (d.getMonth() + 1),
           day = '' + d.getDate(),
           year = d.getFullYear();
   
       if (month.length < 2) 
           month = '0' + month;
       if (day.length < 2) 
           day = '0' + day;
   
       return [year, month, day].join('-');
   }
 
    //console.log();
    const getEmployee=async ()=>
    {
      try{
          
          const response= await   apiPost('getemployeedata', {employeeUID:id
                 });
          if(!response.data.error)
          {
               set(response.data.EmployeeData[0]);
               setName(response.data.EmployeeData[0].name);
               setDOB(formatDate(new Date(response.data.EmployeeData[0].dateofBirth)));
               setcontactNo(response.data.EmployeeData[0].contactNo);
               setparentContactNo(response.data.EmployeeData[0].parentsContactNo);
               setpanCardNo(response.data.EmployeeData[0].pan);
               setaadharNo(response.data.EmployeeData[0].aadhar);
               seteducationQualification(response.data.EmployeeData[0].qualification);
               setExperience(response.data.EmployeeData[0].previousExperience);
               setMail(response.data.EmployeeData[0].personalEmail);
               setAddress(response.data.EmployeeData[0].address);
               setState(response.data.EmployeeData[0].state);
               setpincode(response.data.EmployeeData[0].pincode);
               setaccountNo(response.data.EmployeeData[0].accountNumber);
               setifscNo(response.data.EmployeeData[0].ifscCode);
               setbranchName(response.data.EmployeeData[0].branchName);
               setofficialEmail(response.data.EmployeeData[0].officialEmail);
               setofficePassword(response.data.EmployeeData[0].password);
               setEmployeeType(response.data.EmployeeData[0].empTypeID);
               var temp1=[];
               temp1['panImage']=response.data.EmployeeData[0].panImage;
               temp1['aadharFrontImage']=response.data.EmployeeData[0].aadharFrontImage;
               temp1['aadharBackImage']=response.data.EmployeeData[0].aadharBackImage;
               setEmpFilesArray(temp1);
              /*  setuserUID(response.data.EmployeeData[0].userUID);
               setroleUID(response.data.EmployeeData[0].roleUID); */
              /*  setpanFile(response.data.EmployeeData[0].panImage);
               setaadharFrontFile(response.data.EmployeeData[0].aadharFrontImage);
               setaadharBackFile(response.data.EmployeeData[0].aadharBackImage);
               setcompanyLetterFile(response.data.EmployeeData[0].previousOffer);
               setexperienceFile(response.data.EmployeeData[0].previousExperienceDoc);
               setpayslipOneFile(response.data.EmployeeData[0].payslip1);
               setpayslipTwoFile(response.data.EmployeeData[0].payslip2);
               setpayslipThreeFile(response.data.EmployeeData[0].payslip3); */
               
            
            console.log(formatDate(new Date(response.data.EmployeeData[0].dateofBirth))); 
              
          }
          else
          { 
              dispatch(toastAction(toastConstant.setToast,response.data.errorMessage));
              dispatch(toastAction(toastConstant.errorToast));
          }
         
       }
       catch(error) {
             dispatch(toastAction(toastConstant.setToast,'Internal Server Error'));
             dispatch(toastAction(toastConstant.errorToast));
             console.log(error);
      }
    }
    const [empTypeList,setEmpTypeList]=useState([]);
    const [employeeType,setEmployeeType]=useState('');
    const getEmpTypeList=async ()=>
   {
     try{
              
         const response= await   apiPost('getemployeetypelist', {});
         if(!response.data.error)
         {
             setEmpTypeList(response.data.empTypeList);
         }
         else
         { 
             dispatch(toastAction(toastConstant.setToast,response.data.errorMessage));
             dispatch(toastAction(toastConstant.errorToast));
         }
        
      }
      catch(error) {
            dispatch(toastAction(toastConstant.setToast,'Internal Server Error'));
            dispatch(toastAction(toastConstant.errorToast));
            console.log(error);
     }
   }
        
    
   
    const dispatch=useDispatch();
    const history = useHistory();
    useEffect(() => {
       window.menuFunctionVariable.init();
       window.addEventListener('resize', async function() {
            
          var innerWidth=window.innerWidth?window.innerWidth:null;
          if(innerWidth!=null)
          { 
               if(innerWidth>=992)
               {
                   document.getElementById("navigation").style.display = "block";
               }
               else if(innerWidth<=990)
               {
                   document.getElementById("navigation").style.display = "none";
               }
          }
        });
        getStateList();
     }, []);
   
     //state variable
     const[stateList,setStateList]=useState([]);
     const getStateList= async()=>{
          try{
            
             const returnapi= await  apiPost('getstatelist',{});
             if(!returnapi.data.error)
             {
                setStateList(returnapi.data.StateList);
               
             }
            
          }
          catch(error) {
             console.log(error);
         }
       
     }
      // file clear
      const[fileClearName,setFileClearName]=useState('');
 
    //file onchange function lists
    const [panFile, setpanFile] = useState(null);
    const panFileChange = (file) => {
       
       if(file.length!=0){setpanFile(file);setpanFileClass("font-13 text-muted");}
      
    };
 
    const [aadharFrontFile, setaadharFrontFile] = useState(null);
    const aadharFrontFileChange = (file) => {
       if(file.length!=0){setaadharFrontFile(file);setaadharOneClass("font-13 text-muted");};
    };
 
    const [aadharBackFile, setaadharBackFile] = useState(null);
    const aadharBackFileChange = (file) => {
       if(file.length!=0){setaadharBackFile(file);setaadharTwoClass("font-13 text-muted");};
    };
 
    const [companyLetterFile, setcompanyLetterFile] = useState(null);
    const companyLetterFileChange = (file) => {
       if(file.length!=0){setcompanyLetterFile(file)};
    };
 
    const [experienceFile, setexperienceFile] = useState(null);
    const experienceFileChange = (file) => {
       if(file.length!=0){setexperienceFile(file)};
    };
 
    const [payslipOneFile, setpayslipOneFile] = useState(null);
    const payslipOneFileChange = (file) => {
       if(file.length!=0){setpayslipOneFile(file)};
    };
    
    const [payslipTwoFile, setpayslipTwoFile] = useState(null);
    const payslipTwoFileChange = (file) => {
       if(file.length!=0){setpayslipTwoFile(file)};
    };
    
    const [payslipThreeFile, setpayslipThreeFile] = useState(null);
    const payslipThreeFileChange = (file) => {
       if(file.length!=0){setpayslipThreeFile(file)};
    };
 
    //file upload validation variables
    const [panFileClass, setpanFileClass] = useState("font-13 text-muted");
    const [aadharOneClass, setaadharOneClass] = useState("font-13 text-muted");
    const [aadharTwoClass, setaadharTwoClass] = useState("font-13 text-muted");
 
 
    //form input varibles
    const [empName, setName] = useState("");
    const [DOB, setDOB] = useState("");
    const [contactNo, setcontactNo] = useState("");
    const [parentContactNo, setparentContactNo] = useState("");
    const [panCardNo, setpanCardNo] = useState("");
    const [aadharNo, setaadharNo] = useState("");
    const [educationQualification, seteducationQualification] = useState("");
    const [experience, setExperience] = useState("");
    const [mail, setMail] = useState("");
    const [address, setAddress] = useState("");
    const [state , setState] = useState("");
    const [pincode , setpincode] = useState("");
    const [accountNo , setaccountNo] = useState("");
    const [ifscNo , setifscNo] = useState("");
    const [branchName , setbranchName] = useState("");
    const [officialEmail , setofficialEmail] = useState("");
    const [officePassword , setofficePassword] = useState("");
    const [empFilesArray, setEmpFilesArray] = useState([]);
  /*   const [userUID, setuserUID] = useState("");
    const [roleUID, setroleUID] = useState(""); */
    //Pan validation variables
    const [panMssage , setPanMssage] = useState("");
    const [panErrorVisible , setPanErrorVisible] = useState("none");
    const [panCardNoPlaceHolder, setPanCardNoPlaceHolder] = useState("Enter your PAN number");
 
    //aadhar validation variables
    const [aadharMssage , setAadharMssage] = useState("");
    const [aadharVisible , setAadharVisible] = useState("none");
    const [aadharNoPlaceHolder, setAadharNoPlaceHolder] = useState("Enter your AADHAR number");
 
    //mail validation variables
    const [mailMssage , setMailMssage] = useState("");
    const [mailVisible , setMailVisible] = useState("none");
    const [mailPlaceHolder, setMailPlaceHolder] = useState("Enter your personal mail");
    const [offmailPlaceHolder, setOffMailPlaceHolder] = useState("Enter your personal mail");
    //buttonloader
    const [btnLoader , setbtnLoader] = useState(false);
 
   
    
    const [show, setShow] = useState(false);
    const [showViewer, setShowViewer] = useState(false);
    const mailOnBlur=async ()=>
    {
       try{
              
          const response= await  apiPost('validate-mailbyid', {
             "mail":mail,
             "empUID" :id
         });
          if(!response.data.error)
          {
             setMailMssage(response.data.message);
             setMailVisible(response.data.code==1?'block':'none');
             setMailPlaceHolder(mail);
             setMail(response.data.code==1?'':mail);
          }
         
       }
       catch(error) {
          console.log(error);
      }
    }
 
    const offmailOnBlur=async ()=>
    {
       try{
              
          const response= await  apiPost('validate-officialmailbyid', {
             "officialEmail":officialEmail,
             "empUID" :id
         });
          if(!response.data.error)
          {
             setMailMssage(response.data.message);
             setMailVisible(response.data.code==1?'block':'none');
             setMailPlaceHolder(mail);
             setMail(response.data.code==1?'':mail);
          }
         
       }
       catch(error) {
          console.log(error);
      }
    }
 
    const mailKeyUp=(e)=>
    {
       if(mail.length>0){setMailPlaceHolder("Enter your personal mail");}
       
    }
    const officialMail=()=>
    {
      if(officialEmail.length>0){setOffMailPlaceHolder("Enter your Official mail");}
      
    }
    
    const aadharOnBlur=async ()=>
    {
       try{
              
          const response= await  apiPost('validate-aadharbyid', {
             "aadharNo":aadharNo,
             "empUID" :id
         });
          if(!response.data.error)
          {
             setAadharMssage(response.data.message);
             setAadharVisible(response.data.code==1?'block':'none');
             setAadharNoPlaceHolder(aadharNo);
             setaadharNo(response.data.code==1?'':aadharNo);
          }
         
       }
       catch(error) {
          console.log(error);
      }
       
    }
    
    
    const aadharKeyUp=(e)=>
    {
       if(aadharNo.length==12){aadharOnBlur();}
       if(aadharNo.length>0){setAadharNoPlaceHolder("Enter your AADHAR number");}
      
    }
    const aadhar=(event)=>
    {
      
      if(!(event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57)))
      {
         event.preventDefault();
      }
   
    }
    
 
    const panOnBlur=async ()=>
    {
       try{
              
          const response= await  apiPost('/validate-panbyid', {
             "panCode":panCardNo,
             "empUID" :id
         });
          if(!response.data.error)
          {
             setPanMssage(response.data.message);
             setPanErrorVisible(response.data.code==1?'block':'none');
             setPanCardNoPlaceHolder(panCardNo);
             setpanCardNo(response.data.code==1?'':panCardNo);
          }
         
       }
       catch(error) {
          console.log(error);
      }
      
    }
    
    const panKeyUp=(e)=>
    {
       if(panCardNo.length==10){panOnBlur();}
       if(panCardNo.length>0){setPanCardNoPlaceHolder("Enter your PAN number");}
    }
 
    const fileValidtion=(panFileCheck,aadharOneFileCheck,aadharTwoFileCheck)=>
    {
       
       if(panFileCheck){setpanFileClass("font-13 text-muted");}else{setpanFileClass("inputImportantRed")}
       if(aadharOneFileCheck){setaadharOneClass("font-13 text-muted");}else{setaadharOneClass("inputImportantRed")}
       if(aadharTwoFileCheck){setaadharTwoClass("font-13 text-muted");}else{setaadharTwoClass("inputImportantRed")}
       
    }
    const formSubmitConfirmation=(event)=>
    {
       event.preventDefault();
      
        AlertConfirm({
          title: 'Confirm to submit?',
          desc: 'Are you sure to do this...!',
          onOk: () => {
             registration()
          },
          onCancel: () => {
            console.log('cancel');
          }
        });
    }
 
    const Education=(event)=>
    {
      
       if(!(((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123)||(event.charCode==32))||(event.charCode !=8 && event.charCode ==0)))
      {
         event.preventDefault();
      }
   
    }
    const exp=(event)=>
    {
      
       if(!(((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123)||(event.charCode==32))||(event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))))
      {
         event.preventDefault();
      }
   
    }
   const registration=async ()=>
   {
   
   
    const panFileCheck= panFile ? true : false;
    const aadharOneFileCheck= aadharFrontFile ? true : false;
    const aadharTwoFileCheck= aadharBackFile ? true : false;

    

 
    if( (!panFileCheck && empFilesArray['panImage']=='') || (!aadharOneFileCheck && empFilesArray['aadharFrontImage']=='') || (!aadharTwoFileCheck && empFilesArray['aadharBackImage']=='' ))
    {   fileValidtion(panFileCheck,aadharOneFileCheck,aadharTwoFileCheck)
        return;
    }
 
    dispatch(apiAction(apiConstant.setApiStart,{apiName:'updateemployee',loader:true,notificationMessage:true})); 
    setbtnLoader(true);
 
    let formData = new FormData(); 
    formData.append('employeeUID',id);   
    formData.append('empName',empName);   
    formData.append('DOB', DOB);
    formData.append('contactNo', contactNo);
    formData.append('parentContactNo', parentContactNo);
    formData.append('panCardNo', panCardNo);
    formData.append('aadharNo', aadharNo);
    formData.append('educationQualification', educationQualification);
    formData.append('experience', experience);
    formData.append('mail', mail);
    formData.append('address', address);
    formData.append('state', state);
    formData.append('pincode', pincode);
    formData.append('accountNo', accountNo);
    formData.append('ifscNo', ifscNo);
    formData.append('branchName', branchName);
    formData.append('officialEmail', officialEmail);
    formData.append('officePassword', officePassword);
    formData.append('employeeType', employeeType);
    formData.append('userUID', reduxState.userReducer.userUID);
    formData.append('roleUID', reduxState.userReducer.roleUID);   
    formData.append('panFile', panFile ? panFile[0] : "");
    formData.append('aadharFrontFile', aadharFrontFile? aadharFrontFile[0] : "");
    formData.append('aadharBackFile', aadharBackFile? aadharBackFile[0] : "");
    formData.append('companyLetterFile', companyLetterFile? companyLetterFile[0] : "");
    formData.append('experienceFile', experienceFile? experienceFile[0] : "");
    formData.append('payslipOneFile', payslipOneFile? payslipOneFile[0] : "");
    formData.append('payslipTwoFile', payslipTwoFile? payslipTwoFile[0] : "");
    formData.append('payslipThreeFile', payslipThreeFile? payslipThreeFile[0] : "");
 
 
    console.log('form',formData);
 
    try{
       
       const response= await  apiPost('updateemployee',formData
       );
       console.log('response',response);
       if(!response.data.error)
       {
          dispatch(apiAction(apiConstant.setApiSuccess,{loader:false,errorCode:0,errorMessae:'',notificationMessage:false}));
          dispatch(toastAction(toastConstant.setToast,response.data.message));
          dispatch(toastAction(toastConstant.successToast));
          setbtnLoader(false);
          //history.push("/corporate/hr/onroll");
          history.goBack()
       }
       else{
          setbtnLoader(false);
          errorMessage(response.data.errorMessage);
       }
      
    }
    catch(error) {
       setbtnLoader(false);
       console.log(error);
       errorMessage('Internal Server Error');
   }
 
 
   }
   const errorMessage=(msg)=>
   {
    dispatch(toastAction(toastConstant.setToast,msg));
    dispatch(toastAction(toastConstant.errorToast));
    dispatch(apiAction(apiConstant.setApiFailure,{loader:false,errorCode:1,errorMessae:'',notificationMessage:false}));
   }
  //pdf view file variable
  const [pdfViewFile, setPdfViewFile] = useState(null);
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfPreviousBtnVisible, setPdfPreviousBtnVisible] = useState(false);
  const [pdfNextBtnVisible, setPdfNextBtnVisible] = useState(false);
 
  //image view file variable
  const [imgViewFile, setImgViewFile] = useState(null);
 
   const openPDFModel=(name)=>
    {
       setPageNumber(1);
       
      if(name=='companyOfferLetter')
      {
       setPdfViewFile(companyLetterFile[0]);
      }
      if(name=='Experience')
      {
       setPdfViewFile(experienceFile[0]);
      }
      if(name=='pay1')
      {
       setPdfViewFile(payslipOneFile[0]);
      }
      if(name=='pay2')
      {
       setPdfViewFile(payslipTwoFile[0]);
      }
      if(name=='pay3')
      {
       setPdfViewFile(payslipThreeFile[0]);
      }
       setShow(true);
    }
 
    const openImageModel=(name)=>
    {
       
       
      if(name=='pan')
      {
         setFileClearName('panImage');
         setImgViewFile(URL.createObjectURL(panFile[0]));
      }
      if(name=='faadhar')
      {
       setFileClearName('aadharFrontFile');
         setImgViewFile(URL.createObjectURL(aadharFrontFile[0]));
      }
      if(name=='baadhar')
      {
         setFileClearName('aadharBackFile');
         setImgViewFile(URL.createObjectURL(aadharBackFile[0]));
      }
      
       setShowViewer(true);
    }
    const onDocumentLoadSuccess = ({ numPages }) => {
       
       setNumPages(numPages);
       if(numPages==1)
       {
          setPdfPreviousBtnVisible(false);
          setPdfNextBtnVisible(false);
       }
       else
       {
          setPdfPreviousBtnVisible(false);
          setPdfNextBtnVisible(true);
       }
     };
     const nextPage = () => {
       
       const currentPageNumber = pageNumber;
       setPageNumber(currentPageNumber+1);
       setPdfPreviousBtnVisible(true);
       setPdfNextBtnVisible(((currentPageNumber+1)==numPages)?false:true);
     };
     const previousPage = () => {
       
       const currentPageNumber = pageNumber;
       setPageNumber(currentPageNumber-1);
       setPdfNextBtnVisible(true);
       setPdfPreviousBtnVisible(((currentPageNumber-1)==1)?false:true);
       
     };
     const NameValidation=(event)=>
     {
       //accept alphabets only
       if(!((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123)||(event.charCode==32)||event.charCode==46))
       {
          event.preventDefault();
       }
    
        
     }
     
     const PermanentAddress=(event)=>
     {
       if(!(((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123)||(event.charCode==32))||(event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))))
       {
          event.preventDefault();
       }
    
        
     }
     const AccountValidation=(event)=>
     {
       //accept number only
       if(!(event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57)))
       {
          event.preventDefault();
       }
    
        
     }
     const ifscCode=(event)=>
     {
       //accept alphabets and number only
       if(!(((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123)||(event.charCode==32))||(event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))))
       {
          event.preventDefault();
       }
     }
 
     const Pincode=(event)=>
     {
       //accept number only
       if(!(event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57)))
       {
          event.preventDefault();
       }
    
        
     }
 
     const BranchName=(event)=>
    {
       if(!(((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123)||(event.charCode==32))||(event.charCode !=8 && event.charCode ==0)))
      {
         event.preventDefault();
      }
   
    }

    const reset=()=>
    {
      
      history.goBack();
    }
 /*      const resetForm=()=>
     {
       setpanFile(null);
       setaadharFrontFile(null);
       setaadharBackFile(null);
       setcompanyLetterFile(null);
       setexperienceFile(null);
       setpayslipOneFile(null);
       setpayslipTwoFile(null);
       setpayslipThreeFile(null);
       setName("");
       setDOB("");
       setcontactNo("");
       setparentContactNo("");
       setpanCardNo("");
       setaadharNo("");
       seteducationQualification("");
       setExperience("");
       setMail("");
       setAddress("");
       setState("");
       setpincode("");
       setaccountNo("");
       setifscNo("");
       setbranchName("");
     }  */
     const ClearFile=()=>
     {
       if(fileClearName=='panImage','aadharFrontFile','aadharBackFile','setcompanyLetterFile','setexperienceFile','setpayslipOneFile','setpayslipTwoFile','setpayslipThreeFile')
       {
          setShowViewer(false);
          setShow(false);
          setpanFile(null);
          setaadharFrontFile(null);
          setaadharBackFile(null);
          setcompanyLetterFile(null);
          setexperienceFile(null);
          setpayslipOneFile(null);
          setpayslipTwoFile(null);
          setpayslipThreeFile(null);
          setFileClearName('');
 
       }
 
 }
   return(
    <div className="wrapper">
    <div className="container-fluid">
        <div className="alert alert-success" id="msg_text" style={{display:'none'}}>
        </div>
        <div className="row">
            <div className="col-sm-12">
                <div className="page-title-box">
                    <div className="btn-group pull-right">

                    </div>
                    <h4 className="page-title">Update OnRoll Employees</h4>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card-body">

    <div className="container-fluid">
      {/* <div className="row">
      <div className="col-sm-12">
         <div className="page-title-box">
            <div className="btn-group pull-right">
               <ol className="breadcrumb hide-phone p-0 m-0">
                  <li className="breadcrumb-item"><a href="#">Zoter</a></li>
                  <li className="breadcrumb-item"><a href="#">Forms</a></li>
                  <li className="breadcrumb-item active">Masks</li>
               </ol>
            </div>
            <h4 className="page-title">New Employee Registration</h4>
         </div>
      </div>
   </div> */}
      <form onSubmit={formSubmitConfirmation}>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-4">
                    <div className="p-20">
                      <div className="form-group">
                        <label>
                          Name{" "}
                          <span className="inputImportantRed">*</span>
                        </label>
                        <input
                          type="text"
                          onKeyPress={(e) => NameValidation(e)}
                          defaultValue={empName}
                          onChange={(e) => setName(e.target.value)}
                          placeholder="Enter your name"
                          className="form-control"
                          name="name"
                          id="name"
                          maxLength="50"
                          min="3"
                          tabIndex="1"
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          Parent's/Spouse Contact no{" "}
                          <span className="inputImportantRed">*</span>
                        </label>
                        {/*  <input type="text" value={parentContactNo} onChange={e=>setparentContactNo(e.target.value)} placeholder="Enter your parents number"  className="form-control" name="parentsContactNo" minLength="10" maxLength="10" tabIndex="4" required /> */}
                        <PhoneInput
                          country={"in"}
                          value={parentContactNo}
                          onChange={(phone) => setparentContactNo(phone)}
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          Education qualifications{" "}
                          <span className="inputImportantRed">*</span>
                        </label>
                        <input
                          type="text"
                          onKeyPress={(e) => Education(e)}
                          defaultValue={educationQualification}
                          onChange={(e) =>
                            seteducationQualification(e.target.value)
                          }
                          placeholder="Enter your qualifications"
                          className="form-control"
                          name="qualification"
                          tabIndex="7"
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          Permanent Address{" "}
                          <span className="inputImportantRed">*</span>
                        </label>
                        <textarea
                          id="textarea"
                          onKeyPress={(e) => PermanentAddress(e)}
                          onChange={(e) => setAddress(e.target.value)}
                          className="form-control"
                          maxLength="225"
                          rows="1"
                          placeholder="Enter your address"
                          name="address"
                          defaultValue={address}
                          tabIndex="10"
                          required
                        ></textarea>
                      </div>
                      <div className="form-group m-b-0">
                        <label>
                          Account No{" "}
                          <span className="inputImportantRed">*</span>
                        </label>
                        <input
                          type="text"
                          defaultValue={accountNo}
                          onKeyPress={(e) => AccountValidation(e)}
                          onChange={(e) => setaccountNo(e.target.value)}
                          placeholder="Enter your savings account number"
                          className="form-control"
                          name="accountNumber"
                          id="accountNumber"
                          tabIndex="13"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="p-20">
                      <div className="form-group">
                        <label>
                          Date of birth{" "}
                          <span className="inputImportantRed">*</span>
                        </label>
                        <input
                          type="date"
                          defaultValue={DOB}
                          onChange={(e) => setDOB(e.target.value)}
                          className="form-control"
                          placeholder="2017-06-04"
                          id="mdate"
                          name="dateofBirth"
                          tabIndex="2"
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          Pan Card no{" "}
                          <span className="inputImportantRed">*</span>
                        </label>
                        <input
                          type="text"
                          onKeyPress="return (event.charCode > 47 && event.charCode < 58)"
                          onKeyUp={panKeyUp}
                          defaultValue={panCardNo}
                          onChange={(e) => setpanCardNo(e.target.value)}
                          onBlur={panOnBlur}
                          placeholder={panCardNoPlaceHolder}
                          className="form-control"
                          name="pan"
                          minLength="10"
                          maxLength="10"
                          tabIndex="5"
                          required
                        />
                        <div
                          className="inputImportantRed"
                          id="emailprint-error-msg-red"
                          style={{ display: panErrorVisible }}
                        >
                          {panMssage}
                        </div>
                      </div>
                      <div className="form-group">
                        <label>Previous exp if any</label>
                        <input
                          type="text"
                          defaultValue={experience}
                          onKeyPress={(event) => exp(event)}
                          onChange={(e) => setExperience(e.target.value)}
                          placeholder="Enter your working experience if any"
                          className="form-control"
                          name="previousExperience"
                          tabIndex="8"
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          State{" "}
                          <span className="inputImportantRed">*</span>
                        </label>
                        <select
                          className="form-control"
                          name="stateUID"
                          value={state}
                          onChange={(e) => setState(e.target.value)}
                          tabIndex="11"
                          required
                        >
                          {/* <option value="">Select</option> */}
                          {stateList.map((e, key) => {
                            return (
                              <option key={key} value={e.stateUID}>
                                {e.stateName}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="form-group m-b-0">
                        <label>
                          IFSC Code{" "}
                          <span className="inputImportantRed">*</span>
                        </label>
                        <input
                          type="text"
                          placeholder="Enter your bank IFSc code"
                          className="form-control"
                          name="ifscCode"
                          defaultValue={ifscNo}
                          onKeyPress={(e) => ifscCode(e)}
                          onChange={(e) => setifscNo(e.target.value)}
                          tabIndex="14"
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="p-20">
                      <div className="form-group">
                        <label>
                          Contact no{" "}
                          <span className="inputImportantRed">*</span>
                        </label>
                        {/*  <input type="text" placeholder="Enter your contact no" className="form-control" name="contactNo" value={contactNo} onChange={e=>setcontactNo(e.target.value)} minLength="10" maxLength="10" tabIndex="3" required /> */}
                        <PhoneInput
                          country={"in"}
                          value={contactNo}
                          onChange={(phone) => setcontactNo(phone)}
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          AADHAR No{" "}
                          <span className="inputImportantRed">*</span>
                        </label>
                        <input
                          type="text"
                          placeholder={aadharNoPlaceHolder}
                          className="form-control"
                          name="aadhar"
                          onBlur={aadharOnBlur}
                          onKeyUp={aadharKeyUp}
                          onKeyPress={(event) => aadhar(event)}
                          defaultValue={aadharNo}
                          onChange={(e) => setaadharNo(e.target.value)}
                          minLength="12"
                          maxLength="12"
                          tabIndex="6"
                          required
                        />
                        <div
                          className="inputImportantRed"
                          id="emailprint-error-msg-red"
                          style={{ display: aadharVisible }}
                        >
                          {aadharMssage}
                        </div>
                      </div>
                      <div className="form-group">
                        <label>
                          Personal Email{" "}
                          <span className="inputImportantRed">*</span>
                        </label>
                        <input
                          type="email"
                          placeholder={mailPlaceHolder}
                          className="form-control"
                          name="personalEmail"
                          onBlur={mailOnBlur}
                          onKeyUp={mailKeyUp}
                          defaultValue={mail}
                          onChange={(e) => setMail(e.target.value)}
                          tabIndex="9"
                          required
                        />
                        <div
                          className="green"
                          id="emailprint-error-success"
                          style={{ display: "none" }}
                        >
                          Email Id available{" "}
                        </div>
                        <div
                          className="inputImportantRed"
                          id="emailprint-error-msg-red"
                          style={{ display: mailVisible }}
                        >
                          {mailMssage}
                        </div>
                      </div>
                      <div className="form-group">
                        <label>
                          Pincode{" "}
                          <span className="inputImportantRed">*</span>
                        </label>
                        <input
                          type="text"
                          onKeyPress={(e) => Pincode(e)}
                          placeholder="Enter your pincode"
                          className="form-control"
                          name="pincode"
                          defaultValue={pincode}
                          onChange={(e) => setpincode(e.target.value)}
                          minLength="6"
                          maxLength="6"
                          tabIndex="12"
                          required
                        />
                      </div>
                      <div className="form-group m-b-0">
                        <label>
                          Branch Name{" "}
                          <span className="inputImportantRed">*</span>
                        </label>
                        <input
                          type="text"
                          placeholder="Enter your branch name"
                          className="form-control"
                          name="branchName"
                          defaultValue={branchName}
                          onKeyPress={(e) => BranchName(e)}
                          onChange={(e) => setbranchName(e.target.value)}
                          tabIndex="15"
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group">
                      <label>
                        Official Email{" "}
                        <span className="inputImportantRed">*</span>
                      </label>
                      <input
                        type="email"
                        placeholder={mailPlaceHolder}
                        className="form-control"
                        name="officialMail"
                        onBlur={offmailOnBlur}
                        onKeyUp={officialMail}
                        defaultValue={officialEmail}
                        onChange={(e) => setofficialEmail(e.target.value)}
                        tabIndex="9"
                        required
                      />
                      
                      
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group m-b-0">
                      <label>
                        Password
                        <span className="inputImportantRed">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter your Password"
                        className="form-control"
                        name="branchName"
                        defaultValue={officePassword}
                        onKeyPress={(e) => BranchName(e)}
                        onChange={(e) => setbranchName(e.target.value)}
                        tabIndex="15"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group m-b-0">
                      <label>
                      Select Employement Type
                        <span className="inputImportantRed">*</span>
                      </label>
                      <select className="form-control" value={employeeType} name="stateUID"  onChange={(e)=>setEmployeeType(e.target.value)}    required>
                        
         <option value="">Please Select EmployeeType</option> 
         {empTypeList.map((e, key) => {
           return <option key={key} value={e.empTypeUID}>{e.employmentType}</option>;
         })}
        {/*  <option value="reject">Reject</option> */}
      </select>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <h4 className="mt-0 header-title">
                          File Uploads{" "}
                        </h4>
                        <div className="row">
                          <div className="col-xl-4">
                            <div className="card">
                              <div className="card-body">
                                <h4 className="mt-0 header-title">
                                  Pan Upload{" "}
                                  <span className="inputImportantRed">
                                    *
                                  </span>
                                </h4>
                                <div className="dropify-wrapper">
                                  <FileUploader
                                    multiple={true}
                                    handleChange={panFileChange}
                                    name="file"
                                    types={imgFileTypes}
                                    maxSize={100}
                                  />
                                </div>
                                <div className="row">
                                  {panFile ? (
                                    <div className="col">
                                      <i
                                        className="fas fa-eye fileUploaEyeicon"
                                        aria-hidden="true"
                                        onClick={() =>
                                          openImageModel("pan")
                                        }
                                      ></i>
                                    </div>
                                  ) : null}

                                  <div className="col-10">
                                    <span className={panFileClass}>
                                      {panFile
                                        ? `File name: ${panFile[0].name}`
                                        : "no files uploaded yet"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-xl-4">
                            <div className="card">
                              <div className="card-body">
                                <h4 className="mt-0 header-title">
                                  AADHAR Front Upload{" "}
                                  <span className="inputImportantRed">
                                    *
                                  </span>
                                </h4>
                                <div className="dropify-wrapper">
                                  <FileUploader
                                    multiple={true}
                                    handleChange={aadharFrontFileChange}
                                    name="file"
                                    types={imgFileTypes}
                                    maxSize={100}
                                  />
                                </div>

                                <div className="row">
                                  {aadharFrontFile ? (
                                    <div className="col">
                                      <i
                                        className="fas fa-eye fileUploaEyeicon"
                                        aria-hidden="true"
                                        onClick={() =>
                                          openImageModel("faadhar")
                                        }
                                      ></i>
                                    </div>
                                  ) : null}

                                  <div className="col-10">
                                    <span className={aadharOneClass}>
                                      {aadharFrontFile
                                        ? `File name: ${aadharFrontFile[0].name}`
                                        : "no files uploaded yet"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-xl-4">
                            <div className="card">
                              <div className="card-body">
                                <h4 className="mt-0 header-title">
                                  AADHAR back Upload{" "}
                                  <span className="inputImportantRed">
                                    *
                                  </span>
                                </h4>
                                <div className="dropify-wrapper">
                                  <FileUploader
                                    multiple={true}
                                    handleChange={aadharBackFileChange}
                                    name="file"
                                    types={imgFileTypes}
                                    maxSize={100}
                                  />
                                </div>
                                <div className="row">
                                  {aadharBackFile ? (
                                    <div className="col">
                                      <i
                                        className="fas fa-eye fileUploaEyeicon"
                                        aria-hidden="true"
                                        onClick={() =>
                                          openImageModel("baadhar")
                                        }
                                      ></i>
                                    </div>
                                  ) : null}

                                  <div className="col-10">
                                    <span className={aadharTwoClass}>
                                      {aadharBackFile
                                        ? `File name: ${aadharBackFile[0].name}`
                                        : "no files uploaded yet"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-6">
                            <div className="card">
                              <div className="card-body">
                                <h4 className="mt-0 header-title">
                                  Previous exp if any (Attach last company
                                  Offer)
                                </h4>
                                <div className="dropify-wrapper">
                                  <FileUploader
                                    multiple={true}
                                    handleChange={companyLetterFileChange}
                                    name="file"
                                    types={docFileTypes}
                                    maxSize={100}
                                  />
                                </div>

                                <div className="row">
                                  {companyLetterFile ? (
                                    <div className="col">
                                      <i
                                        className="fas fa-eye fileUploaEyeicon"
                                        aria-hidden="true"
                                        onClick={() =>
                                          openPDFModel(
                                            "companyOfferLetter"
                                          )
                                        }
                                      ></i>
                                    </div>
                                  ) : null}

                                  <div className="col-11">
                                    <span className="font-13 text-muted">
                                      {companyLetterFile
                                        ? `File name: ${companyLetterFile[0].name}`
                                        : "no files uploaded yet"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-xl-6">
                            <div className="card">
                              <div className="card-body">
                                <h4 className="mt-0 header-title">
                                  Previous exp if any (Attach last company
                                  Experience)
                                </h4>
                                <div className="dropify-wrapper">
                                  <FileUploader
                                    multiple={true}
                                    handleChange={experienceFileChange}
                                    name="file"
                                    types={docFileTypes}
                                    maxSize={100}
                                  />
                                </div>
                                <div className="row">
                                  {experienceFile ? (
                                    <div className="col">
                                      <i
                                        className="fas fa-eye fileUploaEyeicon"
                                        aria-hidden="true"
                                        onClick={() =>
                                          openPDFModel("Experience")
                                        }
                                      ></i>
                                    </div>
                                  ) : null}

                                  <div className="col-11">
                                    <span className="font-13 text-muted">
                                      {experienceFile
                                        ? `File name: ${experienceFile[0].name}`
                                        : "no files uploaded yet"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-12">
                            <h4 className="mt-0 header-title">
                              Last 3 months Payslips
                            </h4>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-4">
                            <div className="card">
                              <div className="card-body">
                                <h4 className="mt-0 header-title">
                                  Payslip 1
                                </h4>
                                <div className="dropify-wrapper">
                                  <FileUploader
                                    multiple={true}
                                    handleChange={payslipOneFileChange}
                                    name="file"
                                    types={docFileTypes}
                                    maxSize={100}
                                  />
                                </div>
                                <div className="row">
                                  {payslipOneFile ? (
                                    <div className="col">
                                      <i
                                        className="fas fa-eye fileUploaEyeicon"
                                        aria-hidden="true"
                                        onClick={() =>
                                          openPDFModel("pay1")
                                        }
                                      ></i>
                                    </div>
                                  ) : null}

                                  <div className="col-10">
                                    <span className="font-13 text-muted">
                                      {payslipOneFile
                                        ? `File name: ${payslipOneFile[0].name}`
                                        : "no files uploaded yet"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-xl-4">
                            <div className="card">
                              <div className="card-body">
                                <h4 className="mt-0 header-title">
                                  Payslip 2
                                </h4>
                                <div className="dropify-wrapper">
                                  <FileUploader
                                    multiple={true}
                                    handleChange={payslipTwoFileChange}
                                    name="file"
                                    types={docFileTypes}
                                    maxSize={100}
                                  />
                                </div>
                                <div className="row">
                                  {payslipTwoFile ? (
                                    <div className="col">
                                      <i
                                        className="fas fa-eye fileUploaEyeicon"
                                        aria-hidden="true"
                                        onClick={() =>
                                          openPDFModel("pay2")
                                        }
                                      ></i>
                                    </div>
                                  ) : null}

                                  <div className="col-10">
                                    <span className="font-13 text-muted">
                                      {payslipTwoFile
                                        ? `File name: ${payslipTwoFile[0].name}`
                                        : "no files uploaded yet"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-xl-4">
                            <div className="card">
                              <div className="card-body">
                                <h4 className="mt-0 header-title">
                                  Payslip 3
                                </h4>
                                <div className="dropify-wrapper">
                                  <FileUploader
                                    multiple={true}
                                    handleChange={payslipThreeFileChange}
                                    name="file"
                                    types={docFileTypes}
                                    maxSize={100}
                                  />
                                </div>
                                <div className="row">
                                  {payslipThreeFile ? (
                                    <div className="col">
                                      <i
                                        className="fas fa-eye fileUploaEyeicon"
                                        aria-hidden="true"
                                        onClick={() =>
                                          openPDFModel("pay3")
                                        }
                                      ></i>
                                    </div>
                                  ) : null}

                                  <div className="col-10">
                                    <span className="font-13 text-muted">
                                      {payslipThreeFile
                                        ? `File name: ${payslipThreeFile[0].name}`
                                        : "no files uploaded yet"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-12 text-right">
                            <div className="form-group mb-0">
                              <div>
                                {btnLoader ? (
                                  <button
                                    type="submit"
                                    className="btn btn-primary waves-effect waves-light"
                                    disabled="disabled"
                                  >
                                      <i
              className="fa fa-refresh fa-spin"
              style={{ marginRight: "5px" }}
            />
            
                                    Submit
                                  </button>
                                ) : (
                                  <button
                                    type="submit"
                                    className="btn btn-primary waves-effect waves-light"
                                  >
                                    Update
                                  </button>
                                )}
                                &nbsp; &nbsp;
                                <button
                                  type="button"  onClick={reset}
                                  className="btn btn-secondary waves-effect m-l-5"
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      <Modal
        size="xl"
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby="xample-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            <center> PDF Viewer</center>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Document
            file={pdfViewFile}
            onLoadSuccess={(e) => onDocumentLoadSuccess(e)}
            noData={<h4>Please select a file</h4>}
          >
            <Page pageNumber={pageNumber} />
          </Document>
        </Modal.Body>
        <Modal.Footer>
          {pdfViewFile ? (
            <p>
              Page {pageNumber} of {numPages}
            </p>
          ) : null}
          <button
            style={{ display: pdfPreviousBtnVisible ? "block" : "none" }}
            onClick={() => previousPage()}
            type="button"
            className="btn btn-warning waves-effect waves-light"
          >
            Previous
          </button>
          <button
            style={{ display: pdfNextBtnVisible ? "block" : "none" }}
            onClick={() => nextPage()}
            type="button"
            className="btn btn-success waves-effect waves-light"
          >
            Next
          </button>
          <button
            onClick={() => setShow(false)}
            type="button"
            className="btn btn-primary waves-effect waves-light"
          >
            Close
          </button>
          <button
            onClick={ClearFile}
            type="button"
            className="btn btn-danger waves-effect waves-light"
          >
            Remove
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="xl"
        show={showViewer}
        onHide={() => setShowViewer(false)}
        aria-labelledby="xample-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            <center> Image Viewer</center>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            style={{ maxWidth: "100%", minWidth: "100%" }}
            src={imgViewFile}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={() => setShowViewer(false)}
            type="button"
            className="btn btn-primary waves-effect waves-light"
          >
            Close
          </button>
          <button
            onClick={ClearFile}
            type="button"
            className="btn btn-danger waves-effect waves-light"
          >
            Remove
          </button>
        </Modal.Footer>
      </Modal>
    </div>

   


                    </div>
                </div>
            </div> 
        </div> 
    </div>
  
</div>
   );
}
export default EditEmployee;